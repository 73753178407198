import {createClient} from "contentful";


 export  const galleryClient = createClient({
    space:'avloeg08ek2p',
    accessToken:'eCJj0Zo8yCZ1faXRw0_xqILbkSStGOuQNm9bSneSyV4'
})

export  const teamClient = createClient({
    space:'kz6jctq9khpz',
    accessToken:'3aDL8VYJMssm1yJZT0xFGQQ8gJeudH2UbxXVKY0K-GE'
})





export  const client2 = createClient({
    space:'8zkrrovjjbjs',
    accessToken:'Xlpj3mV4Bgzz7mprb5yfYDiPTFp2bQuY4iCwDCXMKVc'
})


export  const blogClient = createClient({
    space: '4jjrc6rphsgd',
    accessToken:'77UwLhcS7oSQErjzW5L8z8O6zj68aK71EFmcf9BGmBs'
})




export const getBlog = async () => {
    try {
        const response = await blogClient.getEntries({
            content_type:'blogs'
        });
        return response.items;

    }
    catch (error){
        console.error(error);
    }
}




export const getMatchFixture = async () => {
    try {
        const response = await client2.getEntries({
            content_type:'matchFixture'
        });
        const  responseSort = response.items.sort((a,b)=>a.fields.id - b.fields.id)
    return responseSort
    }
    catch (error){
        console.error(error);
    }
}


export const getTablesData = async () =>{
     try {
         const response = await client2.getEntries({
             content_type:'tables',
             include: 2

         })


         return response.items;



     }catch (error){
         console.log(error)
     }
}

export const getTeam = async () =>{
    try {
        const response = await teamClient.getEntries({
            content_type:'teams',
            include: 2

        })
        return response.items;

    }catch (error){
        console.log(error)
    }
}

export const getPrevMatch = async () =>{
     try {
         const response = await client2.getEntries({
             content_type:'latestMatchResult'
         })

         return response.items

     }catch (error) {
         console.log(error)
     }

}
export const getNextMatch = async () =>{
    try {
        const response = await client2.getEntries({
            content_type:'nextMatch'
        })
        return response.items

    }catch (error) {
        console.log(error)
    }

}

export const getImages = async () =>{
    try {
        const response = await galleryClient.getEntries({
            content_type:'images'
        })

        return response.items

    }catch (error) {
        console.log(error)
    }

}



