import React from 'react'
import ReactDOM from 'react-dom/client'
import {RouterProvider} from "react-router-dom";
import useBrowserRouter from "./route.jsx";

import './index.css'
import "./main.css"






ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
          <RouterProvider router={useBrowserRouter}/>
  </React.StrictMode>
)

