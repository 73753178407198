import {Background_ImageSwap} from "./Background_ImageSwap.jsx";
import "./Background.css"
import img1 from "../../assets/tico/04dde0b9-ed0f-4f77-9353-ae655a8d6479.jpg"
import img2 from "../../assets/tico/286648082_1150219889097566_2664589582717404549_n.jpg"
import img3 from "../../assets/tico/87660973-563e-4d26-9272-307d3f67ae67.jpg"
import img4 from "../../assets/tico/324242220_692868855653338_971503934921683522_n.jpg"
import img5 from "../../assets/tico/568bdb0d-a8bd-4a8a-9aa7-532646eca804.jpg"
import img6 from "../../assets/tico/3e4a574b-354b-4031-9552-fe6644b72a2a.jpg"


export function Background_Section() {
    const images = [img1,img2,img3,img4,img5,img6]
    return (
        <>

            <Background_ImageSwap myImages={images}  />

        </>
    );
}
