import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from "../assets/Logo01.png"
import {useState} from "react";
import "../Styles/login.css";
import {NavLink} from "react-router-dom";
import image from "../assets/PngItem_1183153.png"


function Copyright(props) {
    return (
        <Typography sx={{fontFamily: "Oswald SemiBold"}} variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link sx={{fontFamily: "Oswald SemiBold"}} color="inherit" href="https://www.ticosportingacademy.ng/">
                Tico Academy
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

export default function SignIn() {
    const [valid,setvalid] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let email = data.get("email")
        let password = data.get("password")
        if (email==="" || password===""){
            setvalid(true);
        }


    };




    return (

        <ThemeProvider theme={theme}>
            <Container component="main">
                <Box className={"login-container"}>
                    <Box
                        component={'main'}
                        className={"tico-logon"}>

                                <NavLink to={'/home'}>
                                    <div className={"logo-desktop"}>
                                    <Avatar src={logo} sx={{m:1,backgroundColor:"deepskyblue", display:"inline-block"}}></Avatar>
                                    <h4> Tico Sport Academy</h4>
                                    </div>
                                </NavLink>

                        <Box component={"div"} className={"tico-logon-looks"}>
                            <Avatar src={logo} className={"logo-mobile"}></Avatar>
                            <Button
                                type="submit"
                                fullWidth
                                variant="outlined"
                                sx={{ mt: 3, mb: 2,padding:0.3 }}
                            >
                                <h4 style={{fontFamily: "Oswald SemiBold"}}>Sign in with</h4>
                                <Avatar sx={{
                                marginLeft:"0.5em",
                                width:"1.4rem",
                                height:"1.4rem"
                            }}  src={"https://w7.pngwing.com/pngs/249/19/png-transparent-google-logo-g-suite-google-guava-google-plus-company-text-logo.png"}/>
                            </Button>
                            <span className={"or"}>or</span>
                            <Typography  component="h1" variant="h5">
                                <h4 style={{fontFamily:'Nunito',}}>Sign in</h4>
                            </Typography>
                            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    error={valid}

                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    error={valid}

                                />
                                <FormControlLabel
                                    control={<Checkbox value="remember" color="primary" />}
                                    label="Remember me"
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2,fontFamily:"Oswald SemiBold" }}
                                >
                                    Sign In
                                </Button>
                                <Grid container>
                                    <Grid item xs>
                                        <Link  sx={{fontFamily:"Oswald SemiBold"}}    href="#" variant="body2">
                                            Forgot password?
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link sx={{fontFamily:"Oswald SemiBold"}} href="/home/signup" variant="body2">
                                            {"Don't have an account? Sign Up"}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>

                    </Box>
                    <div className="tico-logon-image-container">
                        <div className="logon-image">
                            <img src={image} alt=""/>
                        </div>
                    </div>
                </Box>

                <Copyright sx={{ mt: 8, mb: 4,position:"absolute" ,bottom:0,width:"100%"}} />
            </Container>
        </ThemeProvider>
    );
}
