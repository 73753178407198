import * as React from 'react';
import Grid2 from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import {getBlog} from "../../contentful.js";
import {useEffect, useState} from "react";





function Main() {
    const [blog, setBlog] = useState([])


    useEffect(()=>{
        getBlog().then((item)=>setBlog(item))
    },[])

    return (
        <Grid2
            item
            xs={12}
            md={8}
        >
            {
                blog.map(item=>(
                    <div key={item.sys.id}>
                        <Typography variant="h4" gutterBottom> {item.fields.blogHeader}</Typography>
                        <Divider/>
                        <div>
                            <Typography  className={'newsfeed'} component={"body"}> {item.fields.blogBody}</Typography>
                        </div>

                    </div>


                    )
                )
            }

                </Grid2>
    );
}



export default Main;

