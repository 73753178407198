import {createBrowserRouter, Navigate} from "react-router-dom";


import Landing_Page from "./views/Landing_Page.jsx"
import {NavBar} from "./Components/NavBar.jsx";
import {About_Us} from "./views/About_Us.jsx";
import {Apply_Now} from "./views/Apply_Now";
import {Error404} from "./views/Error404.jsx";
import {Contact_Us} from "./views/Contact_Us.jsx";
import {Our_Team} from "./views/Our_Team.jsx";
import {Our_Gallery} from "./views/Our_Gallery";
import {Sport_Blog} from "./views/Sport_Blog";
import SignIn from "./views/SignIn";
import SignUp from "./views/SignUp";
import {Apply} from "./views/Apply.jsx";
import {Fixtures} from "./views/Fixtures.jsx";






const  useBrowserRouter = createBrowserRouter([
    {
        path:'/',
        element:<NavBar/>,
        children: [
            {
                path: "/",
                element: <Navigate to={"/home"} />
            },
            {
                path:'/home',
                element:<Landing_Page></Landing_Page>
            },
            {
                path:'/about',
                element:<About_Us/>
            },

            {
                path:'/apply_now',
                element:<Apply_Now></Apply_Now>
            },
            {
                path:'/apply',
                element:<Apply/>
            },
            {
                path:'/contact',
                element:<Contact_Us/>
            },
            {
                path:'/team',
                element:<Our_Team/>
            },
            {
                path:'/gallery',
                element:<Our_Gallery/>
            },
            {
                path:'/blog',
                element:<Sport_Blog/>
            },
            {
                path:'/fixture',
                element:<Fixtures/>
            }
        ]
    },
    {
        path: '/home/signin',
        element:<SignIn/>,
    },
    {
        path: '/home/signup',
        element:<SignUp/>,
    },
    {
        path:'/*',
        element:<Error404/>
    },


    ])


export default useBrowserRouter ;
