//Material UI Component
import {Container, CssBaseline} from "@mui/material";
import Grid2 from "@mui/material/Grid";
import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

//Css
import "./blog.css"
import Main from './Main';

//Blog Component import
import FeaturedPost from "./FeaturedPost.jsx";
import MainFeaturedPost from "./MainFeaturedPost.jsx";
import Sidebar from "./Sidebar.jsx";

export function Blog() {




    const sidebar = {
        title: 'About',
        description:
           'Tico Academy',
        archives: [
            { title: 'June 2023', url: '#' },
            { title: 'May 2023', url: '#' },
            { title: 'March 2023', url: '#' },
            { title: 'February 2023', url: '#' },
            { title: 'January 2023', url: '#' },

        ],
        social: [
            { name: 'GitHub', icon: GitHubIcon },
            { name: 'Twitter', icon: TwitterIcon },
            { name: 'Facebook', icon: FacebookIcon },
        ],
    };

    return (
        <>
            <CssBaseline/>
            <Container maxWidth={"lg"}>
                <main>
                    <MainFeaturedPost />
                </main>
                <div className={"tico-blog-feacturepost"}>
                    <Grid2 container >
                            <FeaturedPost  />
                    </Grid2>
                </div>
                <div className={"tico-blog-main"}>

                    <Grid2 container spacing={5} sx={{ mt: 3 }}>
                        <Main  />
                        <Sidebar
                            title={sidebar.title}
                            description={sidebar.description}
                            archives={sidebar.archives}
                            social={sidebar.social}
                        />
                    </Grid2>
                </div>


            </Container>
        </>
    );
}
