
//React Component
import React, {useState} from "react";
import Slider from 'react-slick';

//My Component
import {Footer} from "../Components/Footer.jsx";
import {Sub_Header} from "../Components/Sub-Header/Sub_Header.jsx";

//MaterialUI Component
import {
    AddLocationAltTwoTone,
    MailTwoTone, MessageTwoTone,
    PersonTwoTone,
    PhoneCallbackTwoTone,
    WhatsApp
} from "@mui/icons-material";
import {Container} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box/Box";

//Styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../main.css"





 const settings ={
     margin:"30px",
     scroll:false
}


export function Contact_Us() {
    const text= "Contact Us";
    const textmain ="Institution"


    return (

        <div className="container_about ">
            <header className='site-header'>
                <Sub_Header text={text} textmain={textmain} />
            </header>
            <Box className="tico-contact-container">
                <Box className="tico-contact-mainsection">
                    <Container>
                        <Grid2 container spacing={2}>
                            <Grid2 md={12}>
                                <div className="tico-contact-title">
                                    <h2>Location Map <span></span></h2>
                                </div>
                                <div className={ "tico-contact-map"}>

                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d247.7655660252377!2d3.1505384!3d6.490115!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b83dbb42c6d87%3A0xd997f852a5f7552b!2s10%20Adejo%20Kukoyi%20St%2C%20Victoria%20Island%20102101%2C%20Lagos!5e0!3m2!1sen!2sng!4v1683797595491!5m2!1sen!2sng"
                                        width="100%" height="800" style={{border:0}} allowFullScreen="" loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"></iframe>




                                    {/*google map api*/}
                                </div>
                                <div className="tico-contact-title">
                                    <h2>Contact Us At <span></span></h2>
                                </div>
                                <div className={"tico-contact-info"}>
                                    <Grid2 container spacing={3}>
                                        <Grid2 md={3} xs={12}>
                                            <div className="icon"><PhoneCallbackTwoTone sx={{
                                                fontSize:"35px"
                                            }} /></div>
                                            <div>
                                                <div className="message">
                                                    <p > (+234) 8172785594 </p>
                                                </div>
                                            </div>
                                        </Grid2>
                                        <Grid2 md={3} xs={12}>
                                            <div className="icon"><AddLocationAltTwoTone sx={{
                                                fontSize:"35px"
                                            }} /></div>
                                            <div>
                                                <div className="message pad">
                                                    <p>Km 26 National Postgraduate Medical College Alemu-Ketu Ijanikin
                                                        Ojo Badagry Lagos </p>
                                                </div>
                                            </div>
                                        </Grid2>
                                        <Grid2 md={3} xs={12}>
                                            <div className="icon"><MailTwoTone sx={{
                                                fontSize:"35px"
                                            }} /></div>
                                            <div>
                                                <div className="message mail">
                                                 <a href={"ehonsunday@gmail.com "}>
                                                     <p> info@ticosportingacademy<br/>.com.ng </p>
                                                 </a>
                                                </div>
                                            </div>
                                        </Grid2>
                                        <Grid2 md={3} xs={12}>
                                            <div className="icon"><WhatsApp sx={{
                                                fontSize:"35px"
                                            }} /></div>
                                            <div>
                                                <div className="message whatsapp">
                                                    <a href={"https://wa.me/08172785594"}><p>08172785594</p></a>
                                                </div>
                                            </div>
                                        </Grid2>
                                    </Grid2>
                                </div>
                                <div className="tico-contact-title">
                                    <h2>Contact Form <span></span></h2>
                                </div>
                            </Grid2>
                            <Grid2 xs={12} md={9}>
                                <div className="tico-contact-form">
                                    <form noValidate>
                                        <Grid2 container spacing={2}>
                                            <Grid2 md={6} xs={12}>
                                                <label>Name:</label>
                                                <p>
                                                    <input
                                                        placeholder={"Type here"}
                                                        type="text"/>
                                                    <span><PersonTwoTone/></span>
                                                </p>
                                            </Grid2>
                                            <Grid2 md={6} xs={12}>
                                                <label>Email:</label>
                                                <p>
                                                    <input
                                                        placeholder={"Type here"}
                                                        type="text"/>
                                                    <span><MailTwoTone/></span>
                                                </p>
                                            </Grid2>
                                            <Grid2 md={12} xs={12}>
                                                <label>Message:</label>
                                                <p className={"textarea"}>
                                                    <textarea name="u_msg" placeholder="Type here"
                                                              style={{
                                                                  width:"782px" ,
                                                                  height:"145px"
                                                              }}></textarea>
                                                    <span><MessageTwoTone/></span>
                                                </p>
                                            </Grid2>
                                            <p className={"button"}><label><input value="Submit" type="submit"/></label></p>


                                        </Grid2>
                                    </form>

                                </div>
                            </Grid2>
                            <Grid2 md={3}>
                                <div className={"tico-contact-us-text"}>
                                    <Grid2 container spacing={3}>
                                        <Grid2 md={12}>
                                            <h4>Send Us Message !!!</h4>
                                            <p>At Tico Sport Academy, we appreciate your
                                            message, your reviews, and your complaint, so
                                            send us message anytime and get quick response</p>
                                        </Grid2>
                                        <Grid2 md={12}>
                                            <h4>Open Practices</h4>
                                            <p>Providing information about athlete development
                                                pathways, talent identification programs, and athlete
                                                support services in an open and transparent manner,
                                                promoting opportunities for aspiring athletes to access
                                                and benefit from sports academy programs.</p>
                                            <time dateTime="2008-02-14 20:00">Timings: <span>6:00am to 12:00pm</span></time>
                                            <time dateTime="2008-02-14 20:00">Address: <span>Km 26 National Postgraduate Medical College Alemu-Ketu Ijanikin Ojo Badagry Lagos</span></time>
                                        </Grid2>
                                    </Grid2>


                                </div>
                            </Grid2>
                            <Grid2 md={12}>
                                <div className="tico-contact-sponsor">
                                    <div className="tico-contact-title">
                                        <h2>Our Partners <span></span></h2>
                                                <Slider settings={settings}>
                                                    <div></div>
                                                    <div></div>
                                                </Slider>
                                            <div></div>
                                            <div></div>
                                    </div>
                                </div>
                            </Grid2>
                        </Grid2>
                    </Container>
                </Box>
                <Footer/>
            </Box>

        </div>

    );
}
