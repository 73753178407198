
//Assets
import VideoBg from "../assets/Background (video-converter.com).mp4"
import coke from "../assets/coca-cola-logo-black-and-white.png"
import nike from "../assets/icons8-nike-250.png"

//Material UI Component
import Grid2 from "@mui/material/Grid";
import {AddAlarmOutlined, GradeOutlined} from "@mui/icons-material";
import {
    Avatar,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box/Box"


//React Component
import ScrollUpButton from "react-scroll-up-button"
import DateCountdown from 'react-date-countdown-timer';
import {NavLink} from "react-router-dom";
import Slider from 'react-slick';
import {useEffect, useRef, useState} from "react"
import TextTransition, {presets} from "react-text-transition";


//Css Component
import '../main.css';
import Styles from "../Styles/styles.module.css"

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "react-responsive-carousel" // requires a loader

//LightGallery Component
import lg from 'lightgallery';
import lgVideo from "lightgallery/plugins/video";
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-video.css';
import "lightgallery/css/lg-autoplay.css"
import "lightgallery/css/lg-zoom.css"
import 'lightgallery/lib/lg-events';
import 'lightgallery/lib/lg-settings';



//My Component
import {Footer} from "../Components/Footer.jsx";
import {MainSlider, NavSlider} from "../Components/Slick-Component/Sync_Slick";
import {getBlog,getMatchFixture,getTablesData,getPrevMatch,getNextMatch} from "../contentful.js";
import Youtube_Stream from "../Components/Youtube_Stream.jsx";




//export for Landing Page
export default function Landing_Page(){

    // const {user,token}= useStateContext();
    const galleryRef = useRef()


    const options = {
        mode: 'lg-video',
        thumbnail: true,
        download: false,
        share: false,
        speed: 500,
        plugins:[lgVideo],
        videojs:true,
        videojsTheme:'vjs-theme-fantasy'
    };


    const msg = [
        "We lift Players and Expectations",
        "We Believe We  Never Give In",
        "Don’t Play the Occasion, Play the GAMES"]

    const [index,setIndex] = useState(0)

    const [matchFixture, setMatchFixture ] = useState([])
    const [blog, setBlog ] = useState([])
    const [tableData, setTableData] = useState([]);
    const [prevMatch, setPrevMatch] = useState([])
    const [nextMatch, setNextMatch] = useState([])
    let number =1
    let newsNumber = 1

  useEffect(()=>{
      getMatchFixture().then((items)=>setMatchFixture(items))
      getBlog().then(items=>setBlog(items))
      getTablesData().then(items=>setTableData(items))
      getPrevMatch().then(items=>setPrevMatch(items))
      getNextMatch().then((items)=>setNextMatch(items))

    lg(galleryRef.current,options);

  },[])

    const videoSources = [
        {
            src: 'https://www.youtube.com/watch?v=BefXKZmUb8w',
            type: 'video',
            title: 'Video 1',
            thumbnail:'https://i.ytimg.com/vi/BefXKZmUb8w/mqdefault.jpg'
        },
        {
            src: 'https://www.youtube.com/watch?v=UWzxSPKnEl4',
            type: 'video',
            title: 'Video 2',
            thumbnail: 'https://i.ytimg.com/vi/UWzxSPKnEl4/mqdefault.jpg'
        },
        {
            src: 'https://www.youtube.com/watch?v=mmH9FD6g-SE',
            type: 'video',
            title: 'Video 2',
            thumbnail: 'https://i.ytimg.com/vi/mmH9FD6g-SE/mqdefault.jpg'
        },
        {
            src: 'https://www.youtube.com/watch?v=H5MZpOjvJOU',
            type: 'video',
            title: 'Video 2',
            thumbnail: 'https://i.ytimg.com/vi/H5MZpOjvJOU/mqdefault.jpg'
        },
        {
            src: 'https://www.youtube.com/watch?v=E9an19cW_M4',
            type: 'video',
            title: 'Video 2',
            thumbnail: 'https://i.ytimg.com/vi/E9an19cW_M4/mqdefault.jpg'
        },

    ];


    function randomColor() {
        const r = Math.floor(Math.random() * 256); // random red value between 0 and 255
        const g = Math.floor(Math.random() * 256); // random green value between 0 and 255
        const b = Math.floor(Math.random() * 256); // random blue value between 0 and 255
        const color = `rgb(${r}, ${g}, ${b})`; // combine into a CSS color string
        return color;
    }







    const settings = {
        dots: true,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay:true,
        autoplaySpeed:5000,


        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,

                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,


                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,

                },

            },
        ],
    };








    useEffect(()=>{
      const intervalID = setInterval(() =>{
          setIndex(index=>index+1)
      }, 4000);
      return ()=>clearTimeout(intervalID)
    },[])

    const matchFixtureContainer = ()=>(
        matchFixture.map((item)=>(
            <div className="fixture_container" key={item.fields.id}>
                <div className='match'>
                    <div className="match-header">
                        <div className="match-status"></div>
                        <div className="match-tournament" >{item.fields.matchTournament}</div>
                        <div className="match-actions"> <GradeOutlined className='icon-btn'/> <AddAlarmOutlined className='icon-btn'/></div>
                    </div>
                    <div className="match-content">
                        <div className="column">
                            <div className="team team--home">
                                <div className="team-logo">
                                    <img src={item.fields.homeIcon.fields.file.url}/>
                                </div>
                                <h2 className='team-name'>{item.fields.homeName}</h2>
                            </div>
                        </div>
                        <div className="column">
                            <div className="match-details">
                                <div className="match-date">
                                    {item.fields.matchDate} at <strong>{item.fields.matchTime}</strong>
                                </div>
                                <div className="match-score">
                                    <span className={'match-score-number ' }>{item.fields.homeScore}</span>
                                    <span className="match-score-divider">{item.fields.matchDivider}</span>
                                    <span className={"match-score-number " }>{item.fields.awayScore}</span>
                                </div>
                                <div className="match-time-lapsed">{item.fields.matchLapses}</div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="team team--away">
                                <div className="team-logo">
                                    <img src={item.fields.awayIcon.fields.file.url} alt="Away_Icon"/>
                                </div>
                                <h2 className='team-name'>{item.fields.awayName}</h2>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )))

    //Handing the OnTonggle
    const [toggle, setToggle] = useState(true)
    const handleToggle = ()=>{
        if (toggle){
            setToggle(false)
        }
    };


    //NewsLetter Form
    const NewsLetter = ()=>{
        const handleSubmit = (event) => {
            event.preventDefault()
            const data = new FormData(event.currentTarget)
            const name = data.get('name')
            const email = data.get('email')
        }

        return(
            <>
                <form  noValidate onSubmit={handleSubmit}>
                    <label htmlFor="">Subscribe Now</label>
                    <p>For Our Latest Event and Signing ...</p>
                    <input type="text"  name={"name"} id={"name"}  placeholder={'Your Name'}/>
                    <input type="text"  name={"email"} id={"email"}  placeholder={"Enter Your Email"}/>


                    <label className={Styles.newsletter_btn}>
                        <input type="submit" value="Subscribe"/>
                    </label>
                </form>
            </>
        )
    }



return (
<div className={Styles.container}>
       <div className={Styles.videoContainer}>
       {/*// HERO SECTION*/}
       <div className={Styles.overlay}></div>
       <video className={Styles.video} src={VideoBg} autoPlay muted loop onToggle={handleToggle} ></video>
       <div className={Styles.content}><span >TICO</span>  ACADEMY
           <TextTransition  springConfig={presets.wobbly}><p>{msg[index % msg.length]}</p></TextTransition>
           <a href={"/apply"}>
           <Button variant={'outlined'} sx={{
               marginTop:"50px",
               width:120,
               fontFamily:"Oswald SemiBold",
               '&:hover':{
                   color:"#fff",
                   backgroundColor:"deepskyblue"
               }
           }}>Apply Now </Button></a>
       </div>
       {/*// END OF HERO SECTION*/}
   </div>


    {/*MARKET PARTNERS*/}
        <div className={Styles.market}>
                <h4 className={Styles.market_title}>Main Partner</h4>
                <a href={"#"}><img src={coke}/></a>
                <a href={"#"}><img src={nike}/></a>
        </div>
    {/* END OF MARKET PARTNERS*/}


    {/*FIXTURES*/}

    <Box
        sx={{
            width: '97%',
            p: "3%",
            my: '3rem',
            mx:"auto",
            backgroundColor:"rgba(255,255,255)",



        }}
    >
        <Box
            sx={{
                width:'100%',
                borderBottom:'1px solid #efefef',
                padding:'2rem 1rem ',
                boxShadow:'0px 8px 8px -4px #0074b8',
                marginBottom:"40px"


            }}
        >

<header className={Styles.fixture_header}>
    <div className={Styles.feature_title} style={{
        paddingBottom:'0.45rem'
    }}>
        <NavLink  to={"/fixture"}>    <h3 className={Styles.fixture_title}>Tico Match <span >Fixtures</span></h3>        </NavLink>
    </div>
</header>


            <Slider {...settings}>
                {matchFixtureContainer()}
            </Slider>

        </Box>
        {/*END OF FIXTURE*/}


        {/* CONTENT*/}

        <Box sx={{flexGrow:'1'}}>
            <Grid2 container spacing={2}>
                <Grid2 item xs={12} md={8} >
                    <div>

                        <div className={Styles.feature_title}><h2>Featured News</h2></div>
                        {/*<div>Feature Slider</div>*/}

                        {/*Latest Match Result*/}


                        {
                            prevMatch.slice(0,1).map(item=>(
                                <div className={Styles.matches_result} key={item.sys.id}>
                                    <div className={Styles.matches_resultTitle}>
                                        <h4>Latest Match Result</h4>
                                        <span>{item.fields.date +" "+ item.fields.time }</span>
                                    </div>
                                    <ul>
                                        <li>
                                            <div className="latest-result-image"><img cl src={item.fields.homeIcon.fields.file.url} /></div>
                                            <h4><a href="/fixture">{item.fields.homeName}</a></h4>
                                            {/*Player Name*/}
                                            <span></span>
                                        </li>
                                        <li className={Styles.matches_score}>
                                            <h5>FINAL SCORE</h5>
                                            <p>
                                                <strong className={Styles.matches_scoreColor}>{item.fields.homeScore}</strong>
                                                <small>:</small>
                                                {item.fields.awayScore}
                                            </p>
                                        </li>
                                        <li>
                                            <div className="latest-result-image"><img src={item.fields.awayIcon.fields.file.url} /></div>

                                            <h4><a href="/fixture">{item.fields.awayName}</a></h4>
                                            {/*player name*/}
                                            <span></span>
                                        </li>
                                    </ul>
                                </div>
                            ))
                        }

                        {/*End of Latest Match Result*/}


                    </div>
                </Grid2>

                <Grid2 item  md={4} xs={12} sx={{paddingRight:"1%"}}>
                    <div>
                        {/*NEXT MATCH*/}
                        <div className={Styles.next_match +" "+" next-match-mobile" }>
                            <div className={Styles.feature_title}><h2>Next Match</h2></div>
                            {
                                nextMatch.slice(0,1).map(item=>(
                                    <div
                                        key={item.sys.id}
                                        style={{
                                            width:"100%",
                                            float:'left',
                                            backgroundColor:"#f5f5f5f5",
                                            paddingTop:"1rem",
                                            marginBottom:"30px"
                                        }}>


                                        <div className={Styles.next_matchTitle}>
                                            <h5>{item.fields.matchTournament}</h5>
                                            <span>{item.fields.date}</span>
                                        </div>
                                        <ul>
                                            <li>
                                                <img src={item.fields.homeIcon.fields.file.url} alt=""/>
                                                <h6><a href="/fixture">{item.fields.homeName}</a></h6>
                                                <small>Sports And Magazine</small>
                                            </li>
                                            <li>
                                                <div className={Styles.next_matchOption}>
                                                    <h6>{item.fields.time}</h6>
                                                    <small>{item.fields.venue}</small>
                                                </div>
                                            </li>
                                            <li>
                                                <img src={item.fields.awayIcon.fields.file.url} alt=""/>
                                                <h6><a href="/fixture">{item.fields.awayName}</a></h6>
                                                <small>ST Paddy's Institute</small>
                                            </li>
                                        </ul>
                                        <div className={Styles.match_countdown}>
                                            <h6>Game Countdown</h6>
                                            <div className='is-countdown'><DateCountdown style={{fontSize:"2.5rem"}}  dateTo={item.fields.date+" "+item.fields.time+" UTC +01:00"}  mostSignificantFigure={'day'}   /></div>
                                        </div>
                                    </div>

                                ))
                            }
                        </div>


                    </div>

                </Grid2>

            </Grid2>



            <Grid2 container spacing={2}>
                <Grid2 item xs={12} md={8} >
                    <div >








                        {/*Blogs*/}
                        <div className={Styles.feature_title}>
                            <h2>Latest News</h2>
                        </div>
                        <Grid2 container>
                            {

                                blog.slice(0,3).map(item=>(
                                    <Grid2 item md={6} xs={12}>
                                        <Card   key={item.id}>
                                            <CardActionArea>
                                                <NavLink to={"/blog"}>
                                                <CardMedia
                                                    component="img"
                                                    image={item.fields.blogImage.fields.file.url}
                                                    alt={item.blogAlt}
                                                    sx={
                                                        {
                                                            height:"300px"
                                                        }
                                                    }

                                                />
                                                <CardContent>
                                                    <Typography sx={{fontFamily:'Bangers'}} gutterBottom variant="h5" component="div">
                                                        {item.fields.blogHeader}
                                                    </Typography>
                                                    <Typography sx={{fontFamily:'Nunito'}}       variant="body2" color="text.secondary">
                                                        {item.fields.blogDiscription}
                                                    </Typography>
                                                    <Typography    sx={{fontFamily:'Oswald SemiBold', color:"blue"}}   variant="subtitle2" color="text.secondary">
                                                        {item.fields.blogLinkedText}
                                                    </Typography>
                                                </CardContent>
                                                <Box sx={{
                                                    display:'flex',
                                                    alignItems:'center',
                                                   padding:"2px 10px"
                                                }}>
                                                    <Avatar sx={{
                                                        height:"40px",
                                                        width:"40px"
                                                    }} src={item.fields.blogAuthorImage}/>
                                                    <Box>
                                                        <Typography variant={'subtitle2'} component={'h5'} sx={{marginLeft:'10px'}}>{item.fields.blogAuthorName}</Typography>
                                                    </Box>
                                                </Box>
                                                </NavLink>
                                            </CardActionArea>
                                        </Card>
                                    </Grid2>
                                ))

                            }




                            <Grid2 item md={6} xs={12} rowSpacing={-7}>
                                {
                                    blog.slice(0,3).map(item=>(
                                        <Grid2 item md={12} xs={12} sx={{
                                            marginBottom:'0px',
                                            width:'100%',
                                            float:"left"
                                        }}>
                                            <Card   key={item.id}>
                                                <CardActionArea>
                                                    <div className={Styles.blogs_listWrap}>
                                                        <span style={{backgroundColor: randomColor(), color:randomColor()}}>{item.fields.blogTag}</span>
                                                        <h6>
                                                            <a className="blogBody" href="blog">{item.fields.blogBody}</a>
                                                        </h6>
                                                        <time dateTime={'2023-03-01 23:00'}>{item.fields.blogDate}</time>
                                                    </div>
                                                </CardActionArea>
                                            </Card>
                                        </Grid2>
                                    ))
                                }

                            </Grid2>
                        </Grid2>
                        {/* End of Blogs*/}


                        {/* Popular Players*/}
                        <div className={Styles.feature_title}><h2>Popular Players</h2></div>
                        <div className={Styles.player_slider}>
                            <div className={Styles.player_sliderImage}>
                                {/*image slider*/}
                                    <MainSlider/>
                            </div>
                            <div className={Styles.player_sliderNav}>
                                <NavSlider/>
                        </div>
                        </div>
                        {/*End Popular Players*/}



                        {/*TICO VIDEO GALLERY*/}
                        <div className={Styles.feature_title}><h2>Club Media</h2></div>
                            <div className="media-grid">
                                <div ref={galleryRef}>

                                    {videoSources.map((video, index) => (
                                        <a
                                          href={video.src}
                                            data-lg-size="1280-720"
                                            data-lg-video={video.src}
                                            data-lg-thumb={video.thumbnail}
                                            key={index}
                                        >
                                            <img src={video.thumbnail} alt="PRINM"/>
                                        </a>

                                    ))}
                                </div>
                            </div>
                        <div className="youtube_link">
                            <h4>Click <span><a href="youtube.com/@TicoAcademy01" alt="tico_link">Here</a></span> To See More From Our Youtube Channel</h4>
                        </div>
                    </div>
                </Grid2>

                {/*Grid 4 here*/}
                <Grid2 item  md={4} xs={12} sx={{paddingRight:"1%"}}>



                    {/*TABLES*/}
                    <NavLink  to={"/fixture"}>             <div className={Styles.feature_title}><h2>Table</h2></div>  </NavLink>
                    <div className={Styles.table_title}>
                        <ul className={Styles.table_titleRow}>
                            <li>#</li>
                            <li>Teams</li>
                            <li>M</li>
                            <li>W</li>
                            <li>D</li>
                            <li>L</li>
                            <li>PTS</li>
                        </ul>
                    </div>
                        {

                            tableData.map((item)=>(
                                <div key={item.sys.id} className={Styles.content_table}>
                                    <div style={{
                                        fontSize:"0.8rem",
                                        fontFamily:'Bangers',
                                        margin:"5px 0"
                                    }}>
                                        {item.fields.groupId }
                                    </div>
                                    {
                                        item.fields.team.map(row=>(
                                            <ul className={Styles.content_tableRow} key={row.sys.id}>
                                                <li>{number++}</li>
                                                <li>
                                                    <div className={Styles.content_tableLogo}>
                                                        <span>{row.fields.teams}</span>
                                                        <small></small>
                                                    </div>
                                                </li>

                                                <li className={Styles.shift}>{row.fields.mp}</li>
                                                <li className={Styles.shift}>{row.fields.w}</li>
                                                <li className={Styles.shift}>{row.fields.d}</li>
                                                <li className={Styles.shift}>{row.fields.l}</li>
                                                <li className={Styles.shift}>{row.fields.points}</li>


                                            </ul>
                                        ))
                                    }


                                </div>





                            )
                            )
                        }

                     {/*END OF TABLES*/}

                    {/*POPULAR NEWS*/}
                    <div className={Styles.popular_news}>
                        <NavLink  to={"/blog"}>           <div className={Styles.feature_title}><h2>Popular News</h2></div>   </NavLink>
                        <ul>
                            {

                                blog.slice(0,2).map((item)=>{

                                    const color = ()=>{
                                        const r = Math.floor(Math.random()*256)
                                        const g = Math.floor(Math.random()*256)
                                        const b = Math.floor(Math.random()*256)
                                        const color=`rgb(${r},${g},${b})`
                                        return color
                                    }
                                    return(
                                        <li key={item.sys.id}>
                                            <span>{(newsNumber++).toString().padStart(2,"0")}</span>
                                            <div className={Styles.popular_newsText}>
                                                <small style={{backgroundColor:color(),
                                                    color: color()}}>{item.fields.blogTag}</small>
                                            </div>
                                            <h6><a href={"/blog"} >{item.fields.blogHeader}</a></h6>
                                            <time dateTime="2022-02-14 20:00">{item.fields.blogDate}</time>
                                        </li>
                                    )

                                }
                                )
                            }

                        </ul>
                    </div>

                    {/*NewsLetter*/}
                    <div className={Styles.newsletter}>
                        <div className={Styles.feature_title}><h2>NewsLetter</h2></div>
                        <NewsLetter/>
                    </div>
                </Grid2>
            </Grid2>
        </Box>

    </Box>
             <ScrollUpButton  style={{width:"2rem",
                backgroundColor:"#00bfff",
                 height:"auto",
             borderRadius:"50%"}}/>




    {/*END OF CONTENT*/}
    <Footer/>

</div>
)
}
