import {Sub_Header} from "../Components/Sub-Header/Sub_Header.jsx";
import React, {useEffect, useState} from "react";
import "../main.css";
import Box from "@mui/material/Box/Box";
import {Card, CardContent, CardMedia, Container, Typography} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {Footer} from "../Components/Footer.jsx";
import Gallery from "react-photo-gallery";
import {getImages} from "../contentful.js";


export function Our_Gallery() {
    const text = "Images"
    const textmain ="Gallery"
    const [image, setImage] = useState([])



    useEffect( ()=>{
        getImages().then((item)=>setImage(item.map((item)=>(item.fields.image.map(item=>item.fields.file.url)))))
        }
     ,[])


    const flatArray = ()=>{
        return  image.flatMap(innerArray=>innerArray)
    }
const newImage = flatArray().map(value =>({src:value,
    width: 9,
    height: 6 }) )












    return (
        <div>
            <div  className="container_about ">
                <header className='site-header'>
                    <Sub_Header text={text} textmain={textmain} />
                </header>
                <Box className="tico-contact-container">
                    <Box className="tico-contact-mainsection" sx={
                        {
                            marginBottom:"60px"
                        }
                    }>
                        <Container>
                            <Grid2 container spacing={3}>
                                <Grid2 md={12} >
                                    <div className="tico-contact-title">
                                        <h2>Our Gallery <span></span></h2>
                                    </div>
                                    </Grid2>
                                <Grid2 md={12} xs={12}>
                                    <Gallery photos={newImage}/>
                                </Grid2>
                                </Grid2>

                        </Container>

                    </Box>
                    <Footer/>
                </Box>

            </div>


        </div>
    );
}
