import "../../main.css"
import {Container} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";


export function Sub_Header({text,textmain}) {
    return (
        <div className="subheader">
            <span className='subheader-transparent'></span>
            <Container>
                <Grid2 container sx={{
                    marginTop: "6rem"
                }}>
                    <Grid2 xs={12}>
                        <h1>{text}</h1>
                    </Grid2>
                    <Grid2 xs={12}>
                        <ul className='tico-breadcrumb'>
                            <li><a href={"/"+textmain}>{textmain}</a></li>
                            <li>{text}</li>
                        </ul>
                    </Grid2>

                </Grid2>
            </Container>

        </div>
    );
}
