import * as React from 'react';
import {useEffect, useState} from "react";

import Typography from '@mui/material/Typography';
import Grid2 from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import {getBlog} from "../../contentful.js";




function FeaturedPost() {

    const [blog, setBlog] = useState([]);

    useEffect(()=>{
        getBlog().then((items)=>(setBlog(items)))
        },[])

  return (

    <div>
        {
            blog.slice(0,4).map((posts)=>(
                <Grid2 item xs={12} md={6} sx={{margin:"20px"}}>
                    <CardActionArea component="a" href="#">
                        <Card sx={{ display: 'flex' }}>
                            <CardContent sx={{ flex: 1 }}>
                                <Typography component="h2" variant="h5">
                                    {posts.fields.blogHeader}
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary">
                                    {posts.fields.blogDate}
                                </Typography>
                                <Typography variant="subtitle1" paragraph>
                                    {posts.fields.blogDiscription}
                                </Typography>
                                <Typography variant="subtitle1" color="primary">
                                    Continue reading...
                                </Typography>
                            </CardContent>
                            <CardMedia
                                component="img"
                                sx={{ width: 160,height:'250px', display: { xs: 'none', sm: 'block' } }}
                                image={posts.fields.blogImage.fields.file.url}
                                alt={posts.fields.blogAlt}
                            />
                        </Card>
                    </CardActionArea>
                </Grid2>
            ))
        }
    </div>
  );
}



export default FeaturedPost;
