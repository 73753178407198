import * as React from 'react';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

import {getBlog} from "../../contentful.js";
import {useEffect, useState} from "react";

 function MainFeaturedPost() {
const [blog, setBlog] = useState([])

     useEffect(()=>{
         getBlog().then((item)=>setBlog(item))
     },[])

    return (
        <>
            {
                blog.slice(0,1).map((items)=>(
                    <Paper
                        sx={{
                            position: 'relative',
                            top:"200px",
                            color: '#fff',
                            mb: 4,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundImage: `url(${items.fields.blogImage.fields.file.url})`,
                        }}
                    >
                        {/* Increase the priority of the hero background image */}
                        {<img style={{ display: 'none' }} src={items.fields.blogImage.fields.file.url} alt={items.fields.blogAlt} />}
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                right: 0,
                                left: 0,
                                backgroundColor: 'rgba(0,0,0,.3)',
                            }}
                        />
                        <Grid container>
                            <Grid item md={6}>
                                <Box
                                    sx={{
                                        position: 'relative',
                                        p: { xs: 3, md: 6 },
                                        pr: { md: 0 },
                                    }}
                                >
                                    <Typography component="h1" variant="h3" color="inherit" gutterBottom>
                                        {items.fields.blogHeader}
                                    </Typography>
                                    <Typography variant="h5" color="inherit" paragraph>
                                        {items.fields.blogDiscription}
                                    </Typography>
                                    <Link variant="subtitle1" href="#">
                                        {items.fields.blogLinkedText}
                                    </Link>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                ))
            }


        </>
    );
}


 export default  MainFeaturedPost;
