import {Footer} from "../Components/Footer.jsx";
import {Sub_Header} from "../Components/Sub-Header/Sub_Header.jsx";
import "../main.css"
import Box from "@mui/material/Box/Box";
import {Blog} from "../Components/Blog_Component/Blog";


export function Sport_Blog() {
    const text = "News & Events"
    const textmain = "Sport Blogs"
    return (
        <>
            <div className={"container_blog"}>
                <header className='site-header'>
                    <Sub_Header text={text} textmain={textmain} />
                </header>
                <Box component={"div"} className={"tico-blog-box"}>
                    <div className="tico-contact-title">
                        <h2>Our Blog <span></span></h2>
                    </div>
                    <Blog/>
                    <footer className="tico-blog-footer"><Footer/></footer>
                </Box>
            </div>
        </>
    );
}
