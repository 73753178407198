import { useState, useRef, useEffect } from "react";
import "./Background.css"



export function Background_ImageSwap(props) {
    const {myImages} = props;
    const images = myImages;
    const [currentSlide, setCurrentSlide] = useState(0);
    // useRef does not cause a re-render
    let sliderInterval = useRef();
    let switchImages = () => {
        if (currentSlide < images.length - 1) {
            setCurrentSlide(currentSlide + 1);
        } else {
            setCurrentSlide(0);
        }
    };
    useEffect(() => {
        sliderInterval = setInterval(() => {
            switchImages();
        }, 5000);
        return () => {
            clearInterval(sliderInterval);
        };
    });
    return (
        <>
            <div className="imgWrapper">
                {images.map((img, index) => {
                    return (
                        <img
                            src={img}
                            className={
                                index === currentSlide ? "imageActive homeImage" : "image"
                            }
                        />
                    );
                })}
            </div>
        </>
    );
}
