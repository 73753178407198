import {Sub_Header} from "../Components/Sub-Header/Sub_Header.jsx";
import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid2 from "@mui/material/Grid";
import {MailTwoTone, PersonTwoTone} from "@mui/icons-material";
import "../Styles/apply.css"
import {Footer} from "../Components/Footer";

export const Apply = () => {
    const text = "Apply Here"
    const textmain ="Membership"

    return (
        <>

            <div  className="container_about ">

                <header className='site-header'>
                    <Sub_Header text={text} textmain={textmain} />
                </header>
                <Box className="tico-contact-container">
                    <span className="closed-apply">
                        <h1>APPLICATION PORTAL IS CURRENTLY UNAVAILABLE</h1>
                    </span>
                    <Box className="tico-contact-mainsection">
                        <Container>
                            <Grid2 container spacing={2}>
                                <Grid2 md={12}>
                                    <div className="tico-contact-title">
                                        <h2>Application Form <span></span></h2>
                                    </div>
                                    <Grid2 xs={12} md={9} >
                                        <div className="tico-contact-form">
                                            <form noValidate>
                                                <Grid2 container  spacing={3}>
                                                    <Grid2 md={6} xs={12}>
                                                        <label>First Name:</label>
                                                        <p>
                                                            <input
                                                                placeholder={"Type here"}
                                                                type="text"/>
                                                            <span><PersonTwoTone/></span>
                                                        </p>
                                                    </Grid2>
                                                    <Grid2 md={6} xs={12}>
                                                        <label>Surname:</label>
                                                        <p>
                                                            <input
                                                                placeholder={"Type here"}
                                                                type="text"/>
                                                            <span><PersonTwoTone/></span>
                                                        </p>
                                                    </Grid2>
                                                    <Grid2 md={6} xs={12}>
                                                        <label>Email:</label>
                                                        <p>
                                                            <input
                                                                placeholder={"Type here"}
                                                                type="text"/>
                                                            <span><MailTwoTone/></span>
                                                        </p>
                                                    </Grid2>
                                                </Grid2>
                                            </form>
                                        </div>
                                    </Grid2>
                                </Grid2>
                            </Grid2>


                        </Container>
                    </Box>
                </Box>
                <footer>
                    <Footer/>
                </footer>
            </div>

        </>
    );
}
