
//Material UI Component
import { IconButton} from "@mui/material";
import {
    Close,
    FacebookOutlined,
    LinkedIn,
    MenuOutlined,
    Person2Outlined, Twitter,
    WhatsApp
} from "@mui/icons-material";
import {makeStyles} from "@mui/styles";

//React Component
import React from "react"
import { useState} from "react";
import {NavLink, Outlet} from "react-router-dom";

//Asset
import logo from '../assets/Logo01.png'

//Css Component
import styles from  '../Styles/styles.module.css'
import "../main.css"

//My Component
import NavBar_Component from "./NavBar-Component/NavBar_Component.jsx";




const useStyles = makeStyles({
    icons:{
        color : "white",
        fontsize: 1.4,
        fontFamily:"Arial,Helvetica Neue,Helvetica,sans-serif"
    }
})


export function NavBar() {
    const [clicked,setClicked] = useState(false)
    const classes = useStyles();



     const clickedHandler = (event)=>{
        event.preventDefault();
        if (!clicked){
            setClicked(true)
        }else {
            setClicked(false)
        }

    }


    return (
        <>
            {/*NAVBAR*/}
            {/*HEADER*/}
            <div className ={styles.navBarMainContainer}>
                <div className={styles.topInfo}>
                    <div className={styles.topInfoContainer}>
                        <div className={styles.socialContain} >
                            <aside md ={4} className={styles.aside}>

                                <ul  className={styles.social}>
                                    <IconButton  size="large"  edge ="start"
                                                 className={classes.icons}
                                                 sx={{
                                                     color: 'white'}} ><li><a href={"https://web.facebook.com/tico.sunday.9"}><FacebookOutlined/></a></li></IconButton>
                                    <IconButton  size="large"  edge ="start"
                                                 className={classes.icons}
                                                 sx={{
                                                     color: 'white'}}><li><a><LinkedIn/></a></li></IconButton>
                                    <IconButton  size="large"  edge ="start"
                                                 className={classes.icons}
                                                 sx={{
                                                     color: 'white'}}><li><a href={"https://wa.me/08172785594"}><WhatsApp/></a></li></IconButton>
                                    <IconButton  size="large"  edge ="start"
                                                 className={classes.icons}
                                                 sx={{
                                                     color: 'white'}}><li><a> <Twitter/></a></li></IconButton>

                                </ul>

                            </aside>
                            <aside md={8}>
                                <ul  className={styles.socialRight}>
                                    <IconButton  size="large"  edge ="start"
                                                 className={classes.icons}
                                                 sx={{
                                                     color: 'white'}}><li><a href={"/home/signin"}><Person2Outlined/>Sign In</a></li></IconButton>

                                </ul>

                            </aside>
                        </div>
                    </div>
                </div>
                {/*HEADER*/}
                <div>
                    <nav className={styles.navBarContainer}>

                            <div className={styles.logo}>
                                <NavLink to={'/home'}>
                                    <img  src={logo} alt={"logo image"} />
                                </NavLink>
                            </div>
                            <NavBar_Component clicked={clicked} clickedHandler={clickedHandler} />


                        {/*MOBILE MENU */}

                        <div>
                            <IconButton
                                size="large"
                                edge ="start"
                                className={classes.icons}
                                sx={{ mr: 2,
                                    color: 'deepskyblue',
                                position:'fixed',
                                top:"4rem"}}
                                onClick={clickedHandler}
                                id={styles.Icons}>
                                {clicked?<Close />:<MenuOutlined />}
                            </IconButton>
                        </div>
                    </nav>
                </div>

                {/*END OF NAVBAR*/}
            </div>
            <div><Outlet></Outlet></div>
        </>

    );
}

