import {About_Us} from "./views/About_Us.jsx";

export const menuItems = [{
    title: "Home",
    link:"home"

},
    {
        title: "Institution",
        submenu: [
            {
                title: "About Us",
                link:"about"
            },
            {
                title: "Our Team",
                link:"team"
            },
            {
                title: "Contact Us",
                link:"contact"
            }
        ]
    },
    {
        title: "Sport Programs",

        submenu: [{
            title: "Men Sports",
            link:"about#sport-program",
            // submenu: [
            //     {
            //         title: "Soccer",
            //         link:"about#sport-program",
            //     },
            //     {
            //         title: "Basketball",
            //         link:"about#sport-program",
            //     },
            //     {
            //         title: "Tennis",
            //         link:"about#sport-program",
            //     },
            //     {
            //         title: "Golf",
            //         link:"about#sport-program",
            //     },
            //     {
            //         title: "Volleyball",
            //         link:"about#sport-program",
            //     }
            // ]
        },
            {
                title: "Women Sports",
                link:"about#sport-program",

                // submenu: [
                //     {
                //         title: "Soccer",
                //         link:"about#sport-program",
                //     },
                //     {
                //         title: "Basketball",
                //         link:"about#sport-program",
                //     },
                //     {
                //         title: "Tennis",
                //         link:"about#sport-program",
                //     },
                //     {
                //         title: "Golf",
                //         link:"about#sport-program",
                //     },
                //     {
                //         title: "Volleyball",
                //         link:"about#sport-program",
                //     }
                // ]
            },
            {
                title: "Kiddies Sports",
                link:"about#sport-program",
                // submenu: [
                //     {
                //         title: "Soccer",
                //         link:"about#sport-program",
                //     },
                //     {
                //         title: "Basketball",
                //         link:"about#sport-program",
                //     },
                //
                // ]
            },

        ],
    },
    {
        title: "Facilities",
        link:"contact",

        submenu: [{
            title: "Soccer Pitch",
            link:"contact",
        },
            {
                title: "Ourdoor Courts",
                link:"contact",
            },
            {
                title: "Fitness Center",
                link:"contact",
            },
        ],
    },
    {
        title: "Membership",
        submenu: [{
            title: "Register Online",
            link: "apply"
        },

        ],
    },
    {
        title: "News & Events",
        submenu: [
            {
            title: "Sport Blogs",
                link:"blog",
        },
            {
                title: "Fixtures & Table",
                link:"fixture",
            },

        ],
    },
    {
        title: "Gallery",
        submenu: [{
            title: "Photos",
            link:"gallery",
        },
            {
                title: "Videos",
                link:"gallery",
            },
        ],
    },

];
