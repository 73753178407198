
import React, {useEffect, useState} from "react";

import Box from "@mui/material/Box/Box";
import {Card, CardContent, CardMedia} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";

import "../main.css";
import "../Styles/ourteam.css"

import {Footer} from "../Components/Footer.jsx";
import {Sub_Header} from "../Components/Sub-Header/Sub_Header.jsx";
import {getTeam} from "../contentful.js";




export function Our_Team() {
    const text = "Our Team"
    const textmain ="Institution"


    const [team, setTeam] = useState([])

    useEffect(()=>{
        getTeam().then((items)=>(setTeam(items)))

    },[])


    return (
        <>
            <div  className="container_about ">
                <header className='site-header'>
                    <Sub_Header text={text} textmain={textmain} />
                </header>
                <Box className="tico-contact-container">
                    <Box className="tico-contact-mainsection">
                      <Grid2 container spacing={3}>
                          <Grid2 xs={12} md={12}>
                              <div className="tico-contact-title">
                                  <h2>Meet The Team <span></span></h2>
                              </div>
                          </Grid2>
                          <Box component='div' className="tico-player-container">
                              <Paper elevation={23} className="tico-player-wrap">
                                  <Grid2 xs={12} md={12}>
                                      <div className="tico-contact-title">
                                          <h2 style={{fontSize:"1.5rem",marginTop:"1.5rem"}}>Players<span></span></h2>
                                      </div>
                                  </Grid2>
                                  <Grid2 container xs={12} md={12} spacing={2}>
                                      {
                                          team.map((item)=>(
                                              item.fields.players.sort((a,b)=>a.fields.playerNumber - b.fields.playerNumber).map(item=>(
                                                  <Grid2 md={4} xs={12}>
                                                      <Card sx={{ display: 'flex' }}>
                                                          <Box sx={{ display: 'flex', flexDirection: 'column', width:'50%'
                                                          }}>
                                                              <CardContent sx={{ flex: '1 0 auto', alignItems:"center",justifyContent:"center",
                                                                  flexDirection:'column',
                                                                  flexWrap:"wrap",
                                                                  display:"flex"
                                                              }}>
                                                                  <h1 className="playerNumber">{item.fields.playerNumber}</h1>
                                                                  <h1 className="playerName">{item.fields.firstName+' '}</h1>
                                                                  <h1 className="playerName"><span>{item.fields.lastName}</span></h1>
                                                                  <h1 className="position">{item.fields.position}</h1>


                                                              </CardContent>
                                                          </Box>
                                                          <CardMedia
                                                              component="img"
                                                              sx={{ width: "50%", height:"400px" }}
                                                              image={item.fields.playerImage.fields.file.url}
                                                              alt="Live from space album cover"
                                                          />
                                                      </Card>
                                                  </Grid2>
                                              ))
                                          ))
                                      }
                                  </Grid2>


                                  <Grid2 xs={12} md={12}>
                                      <div className="tico-contact-title">
                                          <h2 style={{fontSize:"1.5rem",marginTop:"1.5rem"}}>COACHES<span></span></h2>
                                      </div>
                                  </Grid2>
                                  <Grid2 container xs={12} md={12} spacing={3}>
                                      {
                                          team.map((item)=>(
                                              item.fields.coaches.map(item=>(
                                                  <Grid2 md={4} xs={12}>
                                                      <Card sx={{ display: 'flex' }}>
                                                          <Box sx={{ display: 'flex', flexDirection: 'column', width:'40%'
                                                          }}>
                                                              <CardContent sx={{ flex: '1 0 auto', alignItems:"center",justifyContent:"center",
                                                                  flexDirection:'column',
                                                                  flexWrap:"wrap",
                                                                  display:"flex"
                                                              }}>
                                                                  <h1 className="playerNumber">{item.fields.playerNumber}</h1>
                                                                  <h1 className="playerName">{item.fields.firstName+' '}</h1>
                                                                  <h1 className="playerName"><span>{item.fields.lastName}</span></h1>
                                                                  <h1 className="position">{item.fields.position}</h1>


                                                              </CardContent>
                                                          </Box>
                                                          <CardMedia
                                                              component="img"
                                                              sx={{ width: "60%", height:"400px" }}
                                                              image={item.fields.playerImage.fields.file.url}
                                                              alt="Live from space album cover"
                                                          />
                                                      </Card>
                                                  </Grid2>
                                              ))
                                          ))
                                      }
                                  </Grid2>



                                  <Grid2 xs={12} md={12}>
                                      <div className="tico-contact-title">
                                          <h2 style={{fontSize:"1.5rem",marginTop:"1.5rem"}}>Officials<span></span></h2>
                                      </div>
                                  </Grid2>
                                  <Grid2 container xs={12} md={12} spacing={3}>
                                      {
                                          team.map((item)=>(
                                              item.fields.others.map(item=>(
                                                  <Grid2 md={4} xs={12}>
                                                      <Card sx={{ display: 'flex' }}>
                                                          <Box sx={{ display: 'flex', flexDirection: 'column', width:'40%'
                                                          }}>
                                                              <CardContent sx={{ flex: '1 0 auto', alignItems:"center",justifyContent:"center",
                                                                  flexDirection:'column',
                                                                  flexWrap:"wrap",
                                                                  display:"flex"
                                                              }}>
                                                                  <h1 className="playerNumber">{item.fields.playerNumber}</h1>
                                                                  <h1 className="playerName">{item.fields.firstName+' '}</h1>
                                                                  <h1 className="playerName"><span>{item.fields.lastName}</span></h1>
                                                                  <h1 className="position">{item.fields.position}</h1>


                                                              </CardContent>
                                                          </Box>
                                                          <CardMedia
                                                              component="img"
                                                              sx={{ width: "60%", height:"400px" }}
                                                              image={item.fields.playerImage.fields.file.url}
                                                              alt="Live from space album cover"
                                                          />
                                                      </Card>
                                                  </Grid2>
                                              ))
                                          ))
                                      }
                                  </Grid2>




                              </Paper>

                          </Box>






                      </Grid2>

                    </Box>
                    <Footer/>
                </Box>


            </div>
        </>
    );
}
