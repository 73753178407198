//React Component
import React from "react"
//Object Component
import {menuItems} from "../../menu_data.js";
//My Component
import MenuItems from "./MenuItems";

//Material UI Component
import {Person2Outlined} from "@mui/icons-material";
import {IconButton} from "@mui/material";

//CSS Component
import "../../main.css"
import "./nav-mobile.css"







const Navbar_Components = ({clicked, clickedHandler}) => {

    return (
        <nav className="nav-area" >
            <ul className = {"menus navBar " }  id={clicked?"active":"inactive"}>
                {
                menuItems.map((menu, index) =>
                {
                    const depthLevel = 0;
                    return<MenuItems items = {menu} key = {index} depthLevel = {depthLevel}/>;
                })
            }
            <div className={"login-mobile"}>
                <IconButton  size="large"  edge ="start">
                    <li><a href={"/home/signin"}><Person2Outlined/>Sign In</a></li>
                </IconButton>
            </div>

            </ul>
        </nav>
    );
};

export default  Navbar_Components;
