import {useStateContext} from "../Contexts/ContextProvider.jsx";
import {Navigate} from "react-router-dom";



export function Apply_Now() {
      const {user,token} = useStateContext()
    if (!token){
        return (
            <Navigate to={'/login'}></Navigate>
        );}
    return (
        <div>apply now</div>
    );
}
