
//React Component
import React, { useState } from "react";

//My Assets
import icon_text from "../assets/icon_text.png"
import img_turks from "../assets/tico/WhatsApp Image 2024-01-02 at 19.30.13.jpeg"
import img_vonik_1 from "../assets/tico/WhatsApp Image 2024-01-02 at 19.33.25 (1).jpeg"
import img_vonik_2 from "../assets/tico/WhatsApp Image 2024-01-02 at 19.33.25.jpeg"


//MaterialUI Component
import Box from "@mui/material/Box/Box";
import {Button, Container, Paper, Typography} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {makeStyles} from "@mui/styles";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";

//My Component
import {Sub_Header} from "../Components/Sub-Header/Sub_Header.jsx";
import {Background_Section} from "../Components/Background-Switch/Background_Section.jsx";
import {Footer} from "../Components/Footer.jsx";

//CSS
import "../main.css"
import "../Styles/ourteam.css"


export function About_Us() {
    const text = "About Us";
    const textmain ="Institution"

    // BOX DESIGN FOR ACHIEVEMENT
    const useStyles = makeStyles({
        card: {
            marginTop: "2rem",
            width: '100%',
            padding: 0,
            float: 'left',
            border: '0.1rem solid rgb(239, 239, 239)',
            marginBottom: '0.8rem',
            position: 'relative',
            transition: 'box-shadow 0.25s ease 0s',
            '&:hover': {
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            },
        },
        media: {
            width: '100%', // Adjust the width to 100%
            height: '200px', // Set the height
            position: 'relative',
            display: 'block',
            margin: '0px',
        },
        cardContent: {
            padding: '0.5rem',
        },
        title: {
            whiteSpace: 'normal',
            fontSize: '1.8rem',
            lineHeight: '2.3rem',
            color: 'rgb(34, 34, 34)',
            textAlign: 'center',
            margin: '3.2rem 0px 1.8rem',
            fontWeight: 400,
            maxHeight: '4.8rem',
            overflow: 'hidden',
            transition: 'margin 0.3s ease-out 0s',
        },
        description: {
            overflow: 'hidden',
            margin: '3.2rem 0px 1.8rem',
            color: 'rgb(34, 34, 34)',
            fontSize: '1.2rem',
            lineHeight: '1.8rem',
            textAlign: 'center',
            opacity: 1,
            height: '0',
            maxHeight: 'none',
            transition: 'height 0.25s ease 0s, opacity 0.25s ease 0s',
        },
        cardHovered: {
            '&:hover $description': {
                height: '5rem', // Adjust the height to your preference
                opacity: 1,
            },
        }

    })

    const AchieveCard = (pros)=>{
        const {country, player, club, division,link} = pros
        const country_name = country
        const player_name = player
        const club_name = club
        const division_name = division
        const link_name = link

        const classes = useStyles();
        return(
            <>
            <Grid2 item xs={12} md={4}>
                <Card className={`${classes.card} ${classes.cardHovered}`}>
                    {/* Remove the CardMedia if you want to remove the image */}
                    <CardMedia
                        component="img"
                        className={classes.media}
                        image={link_name}
                        alt="Sample Image"
                    />
                    <CardContent className={classes.cardContent}>
                        <Typography className={classes.title + " playerNumber" } gutterBottom variant="h2" component="div">
                            {country_name}
                        </Typography>
                        <Typography className={classes.description  + " playerNumber"  } variant="h4"  component="div"  >
                            {player_name}
                        </Typography>
                        <Typography className={classes.description + " playerNumber" } variant="h5"  component="div"  >
                            {club_name}
                        </Typography>
                        <Typography className={classes.description + " playerNumber" } variant="h5"  component="div"  >
                            {division_name}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid2>
            </>
        )
    }


    return (
        <div className='container_about' >
            <header className='site-header'>
                <Sub_Header text={text} textmain={textmain} />
            </header>
            <div className="background">
                <Background_Section/>
                <span className='subheader-transparent'></span>
                <div className="tico-about-header">
                    <strong>Welcome to Tico Sports Academy</strong>
                    <h1>Where Champions are Made</h1>
                </div>

            </div>
            <Box
                className="tico-about-header-details"
                sx={{
                    width:"100vw" ,
                    height:"100%",
                    backgroundColor: '#fff',
                    padding:"5% 3% 2% 3%",
                    transition: "background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s"
                }}>
            <Container>
                <Grid2 container >
                    <Grid2 sm={12}>
                        <div className='about-us-details-wrap'>
                            <div className='details-container'>
                                <h3>Who We Are</h3>
                                <div className='tico-about-details'>
                                    <p>Tico Football Academy was established by Isa Yaqoob and Ehon Sunday in 2016 in community called Ketu-Ijanikin Ojo
                                    Badegry Expressway Lagos Nigeria</p>
                                    <p>
                                        Our aim is to build young talented players from the age of 10 to 22 years to take over the Nigeria National Team
                                    </p>
                                </div>
                                <h3>Our Goal</h3>
                                <div className='tico-about-details'>
                                    <p>We are planning to build a three goal soccer field in a good and locative area with complete facilities in place.</p>
                                    <p>We are also planning to make a professional team by qualifying for the third division league </p>
                                </div>
                            </div>
                        </div>
                    </Grid2>
                </Grid2>
            </Container>
            </Box>
            <Box
                className="tico-about-team-details"
                sx={{
                    width:"100vw" ,
                    padding:"5% 3% 2% 3%",
                }}>
                <Container>
                    <Grid2 container >
                        <Grid2 sm={12}>
                            <div className='about-us-details-wrap'>
                                <div className='details-container'>
                                    <h3>Train with Experts.</h3>
                                    <div className='tico-about-details'>
                                        <p>Tico football Academy was fully registered under Nigeria Co-operate Affairs Commision in the year 2017
                                        and went further to registered with Federal Inland Revenue Service in 2018, and Badagry Divisional Football Association,
                                        Lagos Sate Football Association and Nigeria Football Federation(NFF)</p>
                                        <p>
                                            Tico Football Academy is fully affiliated with Badagry Divisional Football Association, Lagos State Football Association and Nigeria
                                            Football Federation.
                                        </p>
                                    </div>
                                    <p><a href="/team">  <Button variant={'outlined'} style={{
                                        marginTop:25,
                                        width:200,
                                        fontFamily:"Oswald SemiBold"
                                    }}>Meet The Team</Button></a></p>
                                </div>
                            </div>
                        </Grid2>
                    </Grid2>
                </Container>
            </Box>

            <Box
                className="tico-about-header-details"
                sx={{
                    width:"100vw" ,
                    height:"100%",
                    backgroundColor: '#fff',
                    padding:"5% 3% 2% 3%",
                    transition: "background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s"
                }}>
                <Container>
                    <Grid2 container >
                        <Grid2 sm={12}>
                            <div className='about-us-details-wrap'>
                                <div className='details-container'>
                                    <h3>League Attempted</h3>
                                        <div className={"tico-contact-info"}>
                                            <Grid2  container>
                                                <Grid2 md={6} xs={12} sx={{marginTop:"2rem"}}>
                                                    <div>
                                                        <div className="message">
                                                            <p>Badagry Divisonal League </p>
                                                        </div>
                                                    </div>
                                                </Grid2>
                                                <Grid2 md={6} xs={12} sx={{marginTop:"2rem"}}>
                                                <div>
                                                    <div className="message">
                                                        <p>Oba Akan Club </p>
                                                    </div>
                                                </div>
                                            </Grid2>

                                                <Grid2 md={6} xs={12} sx={{marginTop:"2rem"}}>
                                                    <div>
                                                        <div className="message">
                                                            <p>Laffa League Lagos Island </p>
                                                        </div>
                                                    </div>
                                                </Grid2>

                                                <Grid2 md={6} xs={12} sx={{marginTop:"2rem"}}>
                                                    <div>
                                                        <div className="message">
                                                            <p>State League </p>
                                                        </div>
                                                    </div>
                                                </Grid2>

                                                <Grid2 md={6} xs={12} sx={{marginTop:"2rem"}}>
                                                    <div>
                                                        <div className="message">
                                                            <p>Federational Cup </p>
                                                        </div>
                                                    </div>
                                                </Grid2>

                                                <Grid2 md={6} xs={12} sx={{marginTop:"2rem"}}>
                                                    <div>
                                                        <div className="message">
                                                            <p>Airmaster Championship Cup </p>
                                                        </div>
                                                    </div>
                                                </Grid2>

                                            </Grid2>
                                        </div>
                                </div>
                            </div>
                        </Grid2>
                    </Grid2>
                </Container>
            </Box>

            <Box
                className="tico-about-team-details"
                sx={{
                    width:"100vw" ,
                    padding:"5% 1% 2% 1%",
                }}>
                    <Grid2 container >
                        <Grid2 sm={12}>
                            <div className='about-us-details-wrap'>
                                <div className='details-container'>
                                    <h3>Achievements</h3>
                                    <Grid2  container columnSpacing={3}>
                                        <AchieveCard country={"Dubai"} player={"Ndukwu Daniel Chibueze "} club={"Al – Hilal United FC 2020 - 2021"  } division={"Second"}
                                         link={"https://scontent.fabv3-2.fna.fbcdn.net/v/t39.30808-6/333098441_750394406382630_4060086304102827742_n.jpg?stp=dst-jpg_p206x206&_nc_cat=104&ccb=1-7&_nc_sid=3d9721&_nc_eui2=AeFkBxXNoqnBp-l1tFdiWAuVcA3AbLXw795wDcBstfDv3kFNsekOvQnGe7yHbHwlY4C4R98f2x6v0KmCtVq3yaEW&_nc_ohc=zsmFjL1AH6sAX-4Bqnd&_nc_zt=23&_nc_ht=scontent.fabv3-2.fna&oh=00_AfCdbjJyM4m4I3bc2CUt3NdNDV2FGOQok1oq_EuKqcsHUQ&oe=6597B88A"}/>
                                        <AchieveCard country={"Dubai"} player={"Patrick Akobo Eje  "} club={"United Sports    2023 "  } division={"Second"} link={"https://scontent.fabv3-2.fna.fbcdn.net/v/t39.30808-6/333156023_860522261706646_7319009448991868957_n.jpg?stp=dst-jpg_p206x206&_nc_cat=102&ccb=1-7&_nc_sid=3d9721&_nc_eui2=AeHg7DbzX5-fcOP4845omh8OA7lbSqx-6tMDuVtKrH7q076saVTd6FV8teINb4vT7-DvDZiDpKXGRiFEBgkp9FFt&_nc_ohc=C_zTMIw4EeMAX_Mo3p3&_nc_zt=23&_nc_ht=scontent.fabv3-2.fna&oh=00_AfCq2JNddUKfV6rdWrpCC9lq56q1a3dzsq5Jdj9nD7Z0rA&oe=6598B2B2"}/>
                                        <AchieveCard country={"Karzastan"} player={"Anthony Peter" } club={"Mega Sport Int. FC  "  } division={"League A"} link={"https://scontent.fabv3-1.fna.fbcdn.net/v/t39.30808-6/348487947_1362891874442595_4052173605391100689_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=efb6e6&_nc_eui2=AeHktvHRSc1xObIi9TcE7WNU0dkI98te1ZbR2Qj3y17Vlv9OyCGSWAovGPUG1iEHODIPurt2UHP7ODc0jIcX3qLa&_nc_ohc=7akhaILfgHwAX99nFcO&_nc_zt=23&_nc_ht=scontent.fabv3-1.fna&oh=00_AfA0z1akC_C5E5ESLdmV0P8vycjPOmCfNC9TRmlnl__eYQ&oe=6599971D"}/>
                                        <AchieveCard country={"Karzastan"} player={"Joel Emmanuel"} club={"Mega Sport Int. FC  "  } division={"League A"} link={"https://scontent.fabv3-1.fna.fbcdn.net/v/t39.30808-6/348487947_1362891874442595_4052173605391100689_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=efb6e6&_nc_eui2=AeHktvHRSc1xObIi9TcE7WNU0dkI98te1ZbR2Qj3y17Vlv9OyCGSWAovGPUG1iEHODIPurt2UHP7ODc0jIcX3qLa&_nc_ohc=7akhaILfgHwAX99nFcO&_nc_zt=23&_nc_ht=scontent.fabv3-1.fna&oh=00_AfA0z1akC_C5E5ESLdmV0P8vycjPOmCfNC9TRmlnl__eYQ&oe=6599971D"}/>
                                        <AchieveCard country={"Karzastan"} player={"George"} club={"Vodnick Fc  2023   "  } division={"J League "} link={img_vonik_1}/>
                                        <AchieveCard country={"Karzastan"} player={"Feromi"} club={"Vodnick Fc  2023   "  } division={"J League "} link={img_vonik_2}/>
                                        <AchieveCard country={"Turkey "} player={"Chigozie Prince Alex "} club={"Karagumruk Sport - Kulubu "  } division={"First division under the 19 team "} link={img_turks}/>
                                    </Grid2>



                                </div>
                            </div>
                        </Grid2>
                    </Grid2>

            </Box>

            <Box
                className="tico-about-team-details"
                sx={{
                    width:"100vw" ,
                    padding:"5% 3% 2% 3%",
                }}>
                <Container>
                    <Grid2 container >
                        <Grid2 sm={12}>
                            <div className='about-us-details-wrap'>
                                <div className='icon_text'>
                                    <img src={icon_text}/>
                                </div>
                            </div>
                        </Grid2>
                    </Grid2>
                </Container>
            </Box>
            <Box className='tico-about-programs'>
                <Container sx={{
                    textAlign:"center",
                    alignItems:"center",
                width:"100%"}}>
                    <Grid2 md={12}><h1 className="programs-title">Your Sport Carrer start here</h1></Grid2>
                </Container>
                <Paper className="flex-row items">
                    <div className="item" id="sport-program">
                        <div>
                            <h1>
                                Men's Sport
                            </h1>
                            <p className="text">
                                Joining our men's sport team offers a great opportunity
                                to build camaraderie with other men who share a passion
                                for sports. Whether you're interested in basketball,
                                soccer, or any other sport, our team provides a great
                                way to stay active and improve your skills.
                            </p>
                            <p><a href="/contact"><Button className="button" variant={'outlined'}>Academics</Button></a></p>
                            <span className="edges"></span>
                        </div>
                    </div>
                    <div className="item">
                        <div>
                            <h1>
                                Women's Sport
                            </h1>
                            <p className="text">
                                Our team welcomes women of all skill levels, from
                                beginners to seasoned athletes. Our experienced
                                coaches will help you improve your skills and develop
                                your confidence
                            </p>
                            <p><a href="/blog">  <Button className="button" variant={'outlined'}>Request Information</Button></a></p>
                        </div>
                        <span className="edges"></span>
                    </div>
                    <div className="item" >
                        <div>
                            <h1>
                            Kid's Sport
                        </h1>
                            <p className="text">
                                We believe that everyone can benefit from participa
                                ting in sports, and we are committed to providing a
                                safe and inclusive environment...
                            </p>
                            <p><a href="/apply">  <Button className="button" variant={'outlined'}>Apply Today!</Button></a></p>
                        </div>
                        <span className="edges"></span>
                    </div>
                  </Paper>
            </Box>
            <Footer/>


        </div>
    );
}
