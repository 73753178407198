
import {Container} from "@mui/material";
import '../main.css';
import Grid2 from "@mui/material/Unstable_Grid2";
import {NavLink} from "react-router-dom";
import {
    AddLocation,
    DoubleArrowTwoTone,
    Facebook,
    Google,
    KeyboardDoubleArrowUp,
    LinkedIn,
    Twitter,
} from "@mui/icons-material";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import whatsapp from '../assets/WhatsAppButtonWhiteSmall.svg'
import {useEffect,useState} from "react";
import {getImages,getBlog} from "../contentful.js";

export function Footer() {
    let number = 1;

    const [blog, setBlog] = useState([]);

    const [image, setImage] = useState([]);

    useEffect(()=>{
        getBlog().then((item)=>setBlog(item));
        getImages().then((items)=>setImage(items));



    },[]);

    const scrollToTop = () => {
      window.scrollTo({
          top:0,
          behavior:'smooth'
      })
    }
    const settings = {
        dots: false,
        infinite: true,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,


                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <div className='footer'>
                <footer id='tico-footer' className='tico-footer'>
                <span className="footer-transparent"></span>
                    <div className="tico-footer-widget">
                        <Container maxWidth={"lg"}>
                            <Grid2  container>
                                {/*FOOTER NEWS FEED*/}
                                <Grid2 md={4} className='widget tico-newsfeed'>
                                        <div className='footer-widget-title'>
                                            <h2>Featured News</h2>
                                        </div>
                                    <ul>
                                        {
                                            blog.slice(0,3).map((item)=>( <li key={item.sys.id}>
                                                <span>{(number++).toString().padStart(2,"0")}</span>
                                                <h6><a href={"/blog"} >{item.fields.blogHeader}</a></h6>
                                                <time dateTime="2022-02-14 20:00">{item.fields.blogDate}</time>
                                            </li>))
                                        }

                                    </ul>
                                </Grid2>
                                {/*USEFUL LINKS*/}
                                <Grid2 md={4} className='widget tico-links'>
                                    <div className='footer-widget-title'>
                                        <h2>Useful Links</h2>
                                    </div>
                                    <ul>
                                        <li>
                                            <DoubleArrowTwoTone/><NavLink className='link' to={'home'}>Home</NavLink>
                                        </li>
                                        <li>
                                            <DoubleArrowTwoTone/><NavLink className='link' to={'about'}>About Us</NavLink>
                                        </li>
                                        <li>
                                            <DoubleArrowTwoTone/><NavLink className='link' to={'contact'}>Contact Us</NavLink>
                                        </li>
                                        <li>
                                            <DoubleArrowTwoTone/><NavLink className='link' to={'team'}>Our Team</NavLink>
                                        </li>
                                        <li>
                                            <DoubleArrowTwoTone/><NavLink className='link' to={'about#apply'}>Women Sports</NavLink>
                                        </li>
                                        <li>
                                            <DoubleArrowTwoTone/><NavLink className='link' to={'about#apply'}>Men Sports</NavLink>
                                        </li>
                                        <li>
                                            <DoubleArrowTwoTone/><NavLink className='link' to={'about#apply'}>Kiddies Sports</NavLink>
                                        </li>
                                        <li>
                                            <DoubleArrowTwoTone/><NavLink className='link' to={'contact'}>Outdoor Courts</NavLink>
                                        </li>
                                        <li>
                                            <DoubleArrowTwoTone/><NavLink className='link' to={'contact'}>Fitness Center</NavLink>
                                        </li>
                                        <li>
                                            <DoubleArrowTwoTone/><NavLink className='link' to={'apply'}>Register Online</NavLink>
                                        </li>
                                        <li>
                                            <DoubleArrowTwoTone/><NavLink className='link' to={'apply'}>Membership Benefits</NavLink>
                                        </li>
                                        <li>
                                            <DoubleArrowTwoTone/><NavLink className='link' to={'gallery'}>Gallery</NavLink>
                                        </li>
                                    </ul>
                                </Grid2>
                                {/*PHOTO GALLERY*/}
                                <Grid2 md={4} className='widget tico-gallery'>
                                    <div className='footer-widget-title'>
                                        <h2>Photo Gallery</h2>
                                        <div className='tico-images'>
                                            <Slider {...settings}>
                                                {
                                                  image.map((item)=>(item.fields.image.map((item,index)=>(
                                                          <div className='images_name'>  <img key={index} src={item.fields.file.url} alt={`Image ${index + 1}`} /></div>
                                                      ))
                                                  ))
                                                }
                                            </Slider>
                                        </div>

                                    </div>

                                </Grid2>
                                {/*COPYRIGHT*/}
                                <Grid2 md={12}>
                                    <div className='tico-copyright'>
                                        <a className='tico-top-back'  onClick={scrollToTop}><KeyboardDoubleArrowUp/></a>
                                        <p>&copy; 2023, All Right Reserved - by <NavLink className='link' href='donabutech.com'>Tico Sporting Academy</NavLink> </p>
                                        <ul className='tico-footer-social'>
                                            <li><NavLink  to={"https://www.google.com/maps/place/10+Adejo+Kukoyi+St,+Victoria+Island+102101,+Lagos/@6.490115,3.1505384,21z/data=!4m9!1m2!2m1!1s12+adejo+kukoyi+street+ketu+ijanikin+ojo+badgry+lagos!3m5!1s0x103b83dbb42c6d87:0xd997f852a5f7552b!8m2!3d6.490085!4d3.1506517!16s%2Fg%2F11tjc41l5k"} className='socalfooter'><AddLocation/></NavLink></li>
                                            <li><NavLink to={"https://web.facebook.com/tico.sunday.9"} className='socalfooter'><Facebook/></NavLink></li>
                                            <li><NavLink to={'ehonsunday@gmail.com'} className='socalfooter'><Google/></NavLink></li>
                                            <li><NavLink className='socalfooter'><LinkedIn/></NavLink></li>
                                            <li><NavLink className='socalfooter'><Twitter/></NavLink></li>
                                            <li><a aria-label="Chat on WhatsApp" href="https://wa.me/08172785594"> <img alt="Chat on WhatsApp" src={whatsapp}></img></a></li>



                                        </ul>
                                    </div>
                                </Grid2>


                            </Grid2>
                        </Container>

                    </div>
                </footer>

        </div>
    );
}
