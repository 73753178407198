import {Sub_Header} from "../Components/Sub-Header/Sub_Header.jsx";
import Box from "@mui/material/Box/Box.js";
import {Container} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {Footer} from "../Components/Footer.jsx";
import React, {useEffect, useState} from "react";
import Styles from "../Styles/styles.module.css";
import {getTablesData} from "../contentful.js";
import {getMatchFixture} from "../contentful.js";

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import "../Styles/fixtures.css"


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        fontFamily:"Nunito"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


function TableData( {columns,rows} ) {

    let number = 1

    return (
        rows.map(table=>(
            <TableContainer component={Paper} key={table.sys.id} className={"tableContainer"}>
                <h1 className="groupsName">{table.fields.groupId}</h1>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            {
                                columns.map(column=>(
                                        <StyledTableCell>{column.Header}</StyledTableCell>

                                ))
                            }

                        </TableRow>
                    </TableHead>
                    <TableBody>
                                {

                                    // table.map((row) => (
                                    table.fields.team.map(row=>(
                                            <StyledTableRow key={row.sys.id}>
                                                <StyledTableCell  component="th" scope="row">{number++}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">{row.fields.teams}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">{row.fields.mp}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">{row.fields.w}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">{row.fields.d}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">{row.fields.l}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">{row.fields.gf}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">{row.fields.ga}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">{row.fields.gd}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">{row.fields.points}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row">{row.fields.positions}</StyledTableCell>
                                            </StyledTableRow> ) )
                                // ))
                                }
                            </TableBody>
                </Table>
            </TableContainer>

        ))

    )
}


    const FixtureTable = (fixtures)=>(
        fixtures.map(item=>(
            <TableContainer component={Paper} key={item.sys.id}>
                <TableBody>
                    <TableRow>
                        <StyledTableCell className="fixtures" component="th" scope="row">{item.fields.matchDate+"   @  "+item.fields.matchTime}</StyledTableCell>
                        <StyledTableCell component="th" scope="row">{item.fields.homeName}</StyledTableCell>
                        <StyledTableCell component="th" scope="row">VS</StyledTableCell>
                        <StyledTableCell component="th" scope="row">{item.fields.awayName}</StyledTableCell>
                        <StyledTableCell component="th" scope="row"><div className="fixturesVenue">{item.fields.venue}</div></StyledTableCell>
                    </TableRow>
                </TableBody>
            </TableContainer>
        ))
    )




export function Fixtures() {

    const text = "Fixture & Tables"
    const textmain ="News & Events"

    const [table , setTable] = useState([])
     const [matchFix, setMatchFix] = useState([])

    useEffect(()=>{
        getTablesData().then(item=>(setTable(item)));
            getMatchFixture().then(item=>setMatchFix(item))

    },[])




    const columns = React.useMemo(
        () => [
            {
                Header: 'S/N',
                accessor: 's/n',
            },
            {
                Header: 'TEAMS',
                accessor: 'teams',
            },
            {
                Header: 'MP',
                accessor: 'mp',
            },
            {
                Header: 'W',
                accessor: 'w',
            },
            {
                Header: 'D',
                accessor: 'd',
            },
            {
                Header: 'L',
                accessor: 'l',
            },
            {
                Header: 'GF',
                accessor: 'gf',
            },
            {
                Header: 'GA',
                accessor: 'ga',
            },
            {
                Header: 'GD',
                accessor: 'gd',
            },
            {
                Header: 'POINTS',
                accessor: 'points',
            },
            {
                Header: 'POS',
                accessor: 'pos',
            },
        ] ,[] )




    return (
        <>
            <div>
                <div  className="container_about ">
                    <header className='site-header'>
                        <Sub_Header text={text} textmain={textmain} />
                    </header>
                    <Box className="tico-contact-container">
                        <Box className="tico-contact-mainsection" sx={
                            {
                                marginBottom:"60px"
                            }
                        }>
                            <Container>
                                <Grid2 container spacing={3}>
                                    <Grid2 md={12} >
                                        <div className="tico-contact-title">
                                            <h2>Fixtures & Table <span></span></h2>
                                        </div>
                                    </Grid2>
                                    <Grid2 md={12} xs={12}>
                                        <div className={Styles.feature_title}><h2>Table</h2></div>
                                        <Container>

                                            <TableData columns={columns} rows={table}/>

                                        </Container>
                                    </Grid2>
                                    <Grid2 md={12} xs={12}>

                                        <div className={Styles.feature_title}><h2>Fixtures</h2></div>
                                        <Container>
                                            {
                                                FixtureTable(matchFix)

                                            }

                                        </Container>
                                    </Grid2>
                                </Grid2>

                            </Container>


                        </Box>
                        <Footer/>
                    </Box>

                </div>


            </div>

        </>
    );
}
