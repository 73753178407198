//React Component
import React, {useEffect, useState} from "react";
import Slider from "react-slick";
import {NavLink} from "react-router-dom";
//MY Component
import {getTeam} from "../../contentful.js";

//CSS Component
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Styles from "../../Styles/styles.module.css";
import "../../main.css"
import "../../Styles/slider.css"



const teamInfo = ()=>{
    const [player, setPlayer] = useState([])

    useEffect(()=>{
        getTeam().then((items)=>setPlayer(items))
    },[])
    return player
}


 export const MainSlider = () => {

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:false,

    };
    return (
        <Slider  {...settings}>
            {
                teamInfo().map(item=>(
                    item.fields.players.sort((a,b)=>a.fields.playerNumber - b.fields.playerNumber).map((item)=>

                        (
                            <div key={item.sys.id} className='playerImage'>
                                <img className={Styles.player_sliderImageImg}  src={item.fields.playerImage.fields.file.url}/>
                            </div>
                        )
                    )

                ))
            }


        </Slider>
    );
};

export const NavSlider = () => {
    const settings = {
        slidesToShow: 4,
        slidesToScroll: 1,
        vertical: true,
        focusOnSelect: true,
        autoplay:true,
        verticalSwiping: true,
        arrows:false,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
              breakpoint: 600,
                settings:{
                  slidesToShow:1,
                    slidesToScroll:1,
                    adaptiveHeight: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    adaptiveHeight:true,

                }
            }
        ]
    };
    return (
        <Slider  {...settings}>
                {
                    teamInfo().map(item=>(
                        item.fields.players.sort((a,b)=>a.fields.playerNumber - b.fields.playerNumber).map((item)=>(

                                <div key={item.fields.playerNumber} className='playerInfo'>
                                    <div className={Styles.player_sliderCaption}>
                                        <span>{item.fields.playerNumber}</span>
                                        <section>
                                            <h6><NavLink to={"/team"}>{item.fields.firstName+' '+item.fields.lastName}</NavLink></h6>
                                            <small>{item.fields.position}</small>
                                        </section>
                                    </div>
                                </div>
                        ))

                    ))
                }


        </Slider>
    );
};
